.formContentBox[data-v-cd2d2a1a] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-cd2d2a1a] {
  width: 100%;
  overflow: auto;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 12px;
}
.formTopic[data-v-cd2d2a1a] {
  margin-bottom: 12px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.disUoloadSty[data-v-cd2d2a1a] .el-upload--picture-card {
  display: none;
}
.disUoloadSty[data-v-cd2d2a1a] .el-upload__tip {
  display: none;
}
.disUoloadSty[data-v-cd2d2a1a] .el-upload--text {
  display: none;
}
[data-v-cd2d2a1a].upload-disabled .el-upload--picture-card {
  display: none !important;
}
[data-v-cd2d2a1a].upload-disabled .el-upload--text {
  display: none !important;
}
.tableBottom[data-v-cd2d2a1a] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-top: 10px;
}